<template>
  <v-card flat>
    <v-data-table
      id="inventory-items"
      :headers="headers"
      :items="inventoryItems"
      :loading="isLoading"
      item-key="id"
      loader-height="1"
      :page.sync="options.page"
      :server-items-length="meta.totalPages"
      hide-default-footer
      fixed-header
    >
      <template v-slot:footer>
        <v-pagination
          v-model="options.page"
          light
          circle
          class="py-4"
          :length="meta.totalPages"
          total-visible="15"
          @input="onPagination"
        ></v-pagination>
      </template>

      <template v-slot:top>
        <v-dialog v-model="dialog" width="500" persistent>
          <v-card>
            <v-card-title>
              {{ $t('actions.add') }} {{ $tc('item.unit', 2) }}
            </v-card-title>

            <v-card-text>
              <QuantityField
                :key="editedItem.id"
                class="mt-5"
                :maximum="
                  editedItem.attributes.category === 'prospect' ? false : true
                "
                :units-on-order="true"
                dense
                filled
                :item="editedItem"
              />
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                light
                depressed
                color="grey lighten-1"
                outlined
                @click="onReset"
              >
                {{ $t('actions.cancel') }}
              </v-btn>
              <v-btn color="primary" depressed @click="onSubmit">
                {{ $t('actions.save') }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog></template
      >

      <template v-slot:item.attributes.description="{ item }">
        <div>{{ item.attributes.description }}</div>
        <div class="mt-1 font-weight-light">
          {{ $t(`inventory.categories.${item.attributes.name}`) }}
        </div>
      </template>

      <template v-slot:item.specie="{ item }">
        <div class="font-weight-light mr-2">
          {{
            item.attributes.customAttributes.specie &&
              $t(`species.${item.attributes.customAttributes.specie.text}`)
                | upperCase
          }}
        </div>
        <div>
          {{
            item.attributes.customAttributes.category &&
              item.attributes.customAttributes.category
          }}
        </div>
      </template>

      <template v-slot:item.dimensions="{ item }">
        <ul>
          <li v-if="item.attributes.dimensions.length">
            <span class="text-caption grey--text"> L</span>
            {{
              toMeters(
                item.attributes.dimensions.length,
                item.attributes.dimensionsUnit
              ) || '·'
            }}
            m
          </li>
          <li v-if="item.attributes.dimensions.width">
            <span class="text-caption grey--text"> W </span>
            {{
              toCentimeters(
                item.attributes.dimensions.width,
                item.attributes.dimensionsUnit
              ) || '·'
            }}
            cm
          </li>
          <li v-if="item.attributes.dimensions.height">
            <span class="text-caption grey--text"> T </span>
            {{
              toMillimeters(
                item.attributes.dimensions.height,
                item.attributes.dimensionsUnit
              ) || '·'
            }}
            mm
          </li>
          <li
            v-if="
              item.attributes.customAttributes &&
                item.attributes.dimensions.diameter
            "
          >
            <span class="grey--text"> ⌀ </span>
            {{
              toCentimeters(
                item.attributes.customAttributes &&
                  item.attributes.dimensions.diameter,
                item.attributes.dimensionsUnit
              ) || '·'
            }}
            cm
          </li>
          <li v-if="item.attributes.volume">
            <span class="grey--text"> Vol. </span>
            {{ item.attributes.volume || '·' }}
            <span
              v-if="
                item.attributes.volume && item.attributes.volumeUnit !== 'm³'
              "
              >{{ item.attributes.volumeUnit }}</span
            >
          </li>
        </ul>
      </template>
      <template v-slot:item.eye="{ item }">
        <v-btn
          class="mx-1"
          color="blue accent-1"
          icon
          small
          @click="
            $router.push({
              name: 'inventory-item-show',
              params: { inventoryItemId: item.id }
            })
          "
        >
          <v-icon small>mdi-eye</v-icon>
        </v-btn>
      </template>
      <template v-slot:item.id="{ item }">
        <v-btn small class="mx-1" fab depressed @click="addTaskInputItem(item)"
          ><v-icon>mdi-plus</v-icon></v-btn
        >
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
import { ORGANIZATION_ID } from '@/common/config.js'
import { TASK_ITEM } from '@/models/task.item'
import { INVENTORY_ITEM } from '@/models/inventory.item'
import { UnitsConversion } from '@/mixins/units.conversion.mixin'
import { mapActions } from 'vuex'
import QuantityField from '@/components/QuantityField'
export default {
  name: 'TaskInputItemEditFormTable',
  components: {
    QuantityField
  },
  mixins: [UnitsConversion],
  props: {
    taskId: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      dialog: false,
      isLoading: true,
      headers: this.getHeaders(),
      editedItem: null
    }
  },
  computed: {
    ...mapGetters({
      isAdmin: 'auth/isAdmin',
      inventoryItems: 'inventoryItem/items',
      meta: 'inventoryItem/meta',
      options: 'inventoryItem/options'
    })
  },

  created() {
    this.onReset()
    this.setPagination({ page: 1, itemsPerPage: 15, status: 'in_stock' })
    this.fetchData()
  },

  methods: {
    ...mapActions({
      filterInventoryItems: 'inventoryItem/filter',
      setPagination: 'inventoryItem/setPagination',
      inputItemCreate: 'inputItem/create',
      inputItemUpdate: 'inputItem/update',
      addNotification: 'notification/add'
    }),

    async onPagination() {
      this.isLoading = true
      this.setPagination(this.options)
      this.$vuetify.goTo('#inventory-items', 'easeInOutCubic')
      await this.filterItems()
      this.isLoading = false
    },
    async fetchData() {
      this.isLoading = true
      await this.filterItems()
      this.isLoading = false
    },
    async filterItems() {
      const {
        woodCategory,
        itemGroup,
        specie,
        page,
        itemsPerPage,
        name
      } = this.options
      const params = {
        organizationId: ORGANIZATION_ID(),
        'page[number]': page,
        'page[size]': itemsPerPage,
        sort: '-updated_at',
        status: 'in_stock',
        woodCategory,
        itemGroup,
        specie,
        name
      }
      await this.$store.dispatch('inventoryItem/filter', params)
    },

    addTaskInputItem(item) {
      this.editedItem = { ...item }
      this.dialog = true
    },

    onReset() {
      this.editedItem = { ...INVENTORY_ITEM() }
      this.dialog = false
    },

    async onSubmit() {
      const taskInputItem = this.mergeSelectedItem(this.editedItem)
      if (!taskInputItem.attributes.itemId) alert('Error: Item not selected')
      try {
        await this.inputItemCreate({ taskId: this.taskId, item: taskInputItem })
        this.createNotification('200', 'success', 2000, {
          title: 'Successfully created'
        })
      } catch (error) {
        if (!error.response) return
        const {
          status,
          data: { errors }
        } = error.response
        return this.createNotification(status, 'error', 10000, errors)
      } finally {
        this.onReset()
      }
    },

    createNotification(status, color, time, messages) {
      this.addNotification({
        status,
        color,
        time,
        messages
      })
    },

    mergeSelectedItem(item) {
      const taskInputItem = { ...TASK_ITEM('inputItem') }
      const { data: itemParent } = item.relationships.parent
      const { data: itemGroup } = item.relationships.itemGroup
      const { customAttributes, quantity } = item.attributes
      const {
        name,
        facilityId,
        volume,
        volumeUnit,
        dimensions,
        dimensionsUnit,
        description
      } = item.attributes

      Object.assign(taskInputItem.attributes, {
        name,
        facilityId,
        volume,
        volumeUnit,
        dimensions,
        dimensionsUnit,
        description,
        itemId: item.id,
        units: quantity,
        itemParentId: itemParent ? itemParent.id : null
      })
      delete customAttributes.blockchain
      customAttributes.itemGroupParentId = itemGroup && itemGroup.id
      Object.assign(
        taskInputItem.attributes.itemCustomAttributes,
        customAttributes
      )
      return taskInputItem
    },

    getHeaders() {
      return [
        {
          text: '',
          align: 'end',
          sortable: false,
          value: 'eye',
          width: 20
        },
        {
          text: this.$t('item.description'),
          align: 'start',
          sortable: false,
          value: 'attributes.description'
        },
        // {
        //   text: this.$t('common.name'),
        //   align: 'start',
        //   sortable: false,
        //   value: 'attributes.name',
        //   width: 200
        // },
        {
          text: this.$t('item.specie'),
          value: 'specie',
          sortable: false
        },
        {
          text: this.$t('item.dimensions'),
          sortable: false,
          value: 'dimensions'
        },
        {
          text: this.$t('inventory.in_stock'),
          value: 'attributes.itemUnitsInStockCount',
          sortable: false
        },
        {
          text: '',
          value: 'id',
          sortable: false,
          align: 'end'
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped></style>
