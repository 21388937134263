import { render, staticRenderFns } from "./TaskInputItemEditFormTable.vue?vue&type=template&id=2ba05f58&scoped=true&"
import script from "./TaskInputItemEditFormTable.vue?vue&type=script&lang=js&"
export * from "./TaskInputItemEditFormTable.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2ba05f58",
  null
  
)

export default component.exports