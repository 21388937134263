<template>
  <v-row>
    <v-col cols="12">
      <v-card class="mt-5" outlined>
        <v-card-title class="text-capitalize text-h4 grey lighten-5"
          >{{ `${action} ${$t('workOrder.input')}` }}
          <v-spacer></v-spacer>
          <v-btn color="primary" outlined @click="$router.go(-1)"
            ><v-icon left>mdi-arrow-left</v-icon>
            {{ $t('common.close') }}</v-btn
          >
        </v-card-title>

        <v-card-text class="mt-5">
          <v-form ref="form">
            <TaskInputItemEditForm :task-id="taskId" />
          </v-form>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import TaskInputItemEditForm from './components/TaskInputItemEditForm'
export default {
  name: 'TaskInputItemEdit',
  components: {
    TaskInputItemEditForm
  },
  props: {
    taskId: {
      type: String,
      default: ''
    }
  },

  computed: {
    action() {
      return this.$t(this.inputItemId ? 'actions.edit' : 'actions.create')
    }
  }
}
</script>
