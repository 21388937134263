<template>
  <div>
    <InventoryItemIndexTableFilter @filter="filterItems" />
    <TaskInputItemEditFormTable ref="table" :task-id="taskId" />
  </div>
</template>

<script>
import TaskInputItemEditFormTable from './TaskInputItemEditFormTable'
import InventoryItemIndexTableFilter from '@/views/inventory-item/components/InventoryItemIndexTableFilter'
export default {
  components: {
    TaskInputItemEditFormTable,
    InventoryItemIndexTableFilter
  },
  props: {
    taskId: {
      type: String,
      default: ''
    }
  },
  methods: {
    filterItems(filter) {
      let options = Object.assign({}, this.options)
      options = { ...options, ...filter }
      this.$store.dispatch('inventoryItem/setPagination', options)
      this.$refs.table.fetchData()
    }
  }
}
</script>
