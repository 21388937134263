export const TASK_ITEM = (type = 'inputItem') => ({
  type,
  attributes: {
    name: null,
    itemId: '',
    facilityId: 'b1817f9b-c71d-4036-8d92-1d7ef1c38604',
    units: 1,
    category: 'raw_product',
    volume: null,
    volumeUnit: 'm³',
    dimensions: { width: null, height: null, length: null },
    dimensionsUnit: 'mm',
    description: '',
    comments: '',
    itemParentId: '',
    itemCustomAttributes: {},
    customAttributes: { packQuantity: 1, partName: null }
  }
})
